<template>
  <v-row justify="end">
    <v-col cols="12" >
      <v-skeleton-loader
          tile
          type="list-item, image"
      ></v-skeleton-loader>
    </v-col>
    <v-col cols="12" md="6" class="mr-auto">
      <v-skeleton-loader
          tile
          type="paragraph"
      ></v-skeleton-loader>
      <v-skeleton-loader
          tile
          type="paragraph"
      ></v-skeleton-loader>
    </v-col>
    <v-col cols="12" md="4">
      <v-skeleton-loader
          type="heading"
          tile
          class="product-actions"
      ></v-skeleton-loader>
      <v-skeleton-loader
          type="heading"
          tile
          class="product-actions"
      ></v-skeleton-loader>
      <v-skeleton-loader
          type="heading"
          tile
          class="product-actions"
      ></v-skeleton-loader>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ProductViewSkeleton"
}
</script>

<style lang="scss" >
.product-actions {
  & .v-skeleton-loader__heading {
    width: 100%;
    margin-bottom: 10px;
    height: 40px;
  }
}
</style>
