
import Vue from "vue";
import BaseBtn from "@/components/base/Button.vue";

export default Vue.extend({
  name: 'ProductLocations',
  components: {BaseBtn, },
  props: {
    locations: Array,
  },
  computed: {
    formattedLocations() {
      return this.locations?.map((i: any) => ({
        ...i,
        label: `${i.road} ${i.houseNumber}, ${i.city}`,
        url: `https://www.google.com/maps/search/?api=1&query=${i.lat},${i.lon}`
      }))
    }
  },
})
