
import Vue from "vue";
import {mapActions, mapState} from "vuex";
import PageLayout from "@/components/common/PageLayout.vue";
import BaseBtn from "@/components/base/Button.vue";
import ProductLocations from "@/components/product/ProductLocations.vue";
import ProductViewSkeleton from "@/components/product/ProductViewSkeleton.vue";

export default Vue.extend({
  name: 'ProductView',
  components: {ProductViewSkeleton, ProductLocations, BaseBtn, PageLayout},
  data() {
    return {
      taxonomyId: '',
      location: null
    }
  },
  computed: {
    ...mapState('product/instance', {
      product: 'instance',
      loading: 'loading'
    }),
    ...mapState('order/instance', {
      order: 'instance',
      orderLoading: 'loading'
    }),
  },
  methods: {
    ...mapActions('product/instance', {
      show: 'show'
    }),
    ...mapActions('order', {
      placeOrder: 'placeOrder'
    }),
    async place() {
      await this.placeOrder(this.product.uuid);
      this.$router.push(`/order/${this.order.uuid}`)
    },
    async initialize() {
      await this.show({uuid: this.$route.params.uuid, query: {state: 'all', trashed: false, format: 'raw'}});
      this.taxonomyId = this.product.taxons[0]?.taxonomyUuid;
      this.location = this.product.locations[0];
    }
  },
  mounted() {
    this.initialize();
  }
})
